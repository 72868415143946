function OTsubmitMe(id) {
    if ($('#OTmyname').val().length < 3) {
        alert('Použijte delší jméno (min. 3 znaky)');
    } else if ($('#OTmyname').val() == 'Vaše jméno') {
        alert('Nevyplnili jste Vaše jméno (min. 3 znaky)');
    } else if ($('#dotaz').val() == 'Sem napište vaši otázku') {
        alert('Nenapsali jste žádný dotaz');
    } else if ($('#dotaz').val() == 'Váš dotaz') {
        alert('Nenapsali jste žádný dotaz');
    } else if ($('#dotaz').val().length < 5) {
        alert('Napište delší dotaz (min. 5 znaků)');
    } else {
        $.ajax({
            type: 'POST',
            url: '/onlineTalk/' + id + '/ask',
            dataType: 'json',
            data: $('#frm-ot').serialize(),
            success: function (res) {
                OTasked(res);
            },
        });
        $('#OTbtnSubmit').attr('disabled', 'disabled');
        $('#OTmsgs').html('');
        $('form').hide();
        $('.VlozitVzkazNadpis').hide();
    }
}

function OTreload() {
    $('form').show();
    $('.dalsiDotaz').hide();
    $('.VlozitVzkazNadpis').show();
}

function OTasked(ret) {
    r = ret;
    if (r.OK == 1) {
        $('#frm-ot').each(function () {
            this.reset();
        });
        $('#OTbtnSubmit').removeAttr('disabled');
    } else {
        $('#OTbtnSubmit').removeAttr('disabled');
    }

    // hack slovakia
    if (r.message == 'Otázka odoslaná') {
        var messCzech = '<div class="dalsiDotaz">Váš dotaz byl odeslán. Děkujeme.</div>';
    } else if (r.message == 'Vyplňte Vaše meno.') {
        var messCzech = '<div class="dalsiDotaz">Vyplňte Vaše jméno</div>';
    } else if (r.message == 'Chyba pri odosielaní.') {
        var messCzech = '<div class="dalsiDotaz">Chyba při odesílání</div>';
    } else if (r.error == 'long_value') {
        var messCzech = r.message;
        $('#frm-ot').show();
        $('#OTquestion').focus();
    } else {
        var messCzech = r.message;
    }

    $('#OTmsgs').html(messCzech);
}

var OTimer = null;
var OTmyvar = true;

function OTupdates() {
    $('#OTshowUpdate').hide();
    $('#OTshowUpdating').show();

    if (OTimer) {
        window.clearTimeout(OTimer);
        OTimer = null;
    }

    $.ajax({
        type: 'GET',
        url:
            '/onlineTalk/' +
            OTid +
            '/update?lastReq=' +
            lastReq +
            (artobj_id ? '&artobj_id=' + artobj_id : ''),
        dataType: 'json',
        success: function (res) {
            if (res.OK == 1) {
                ob = $('#OTitems');
                if (res.message) {
                    if (OTsort == 1)
                        $('#OTitems').append(
                            res.message.substring(105, a.message.lastIndexOf('</div>')),
                        );
                    else $('#OTitems').find('div.record').removeClass('showRecent');
                    $('#OTitems').prepend(res.message).find('div.record');
                }

                lastReq = res.nlastReq;
            }
            OTimer = window.setTimeout('OTupdates();', 60000);
        },
    });

    OTmyvar = true;
    if ($('#OTshowFormR')) $('#OTshowFormR').css('color', 'red');

    $('#OTshowUpdating').hide();
    $('#OTshowUpdate').show();
    window.setTimeout(
        "OTmyvar=false;$('#OTshowUpdate').hide();if($('#OTshowFormR')) $('#OTshowFormR').css('color', 'black');",
        10000,
    );
}
function OTrefresh() {
    if (!OTmyvar) OTupdates();
}
